export default function VisaInfinite() {
	return (
		<div class="flex flex-col gap-8 md:mt-24">
			<img
				src="https://images.ctfassets.net/rdsy7xf5c8dt/oq9u8ytZj41pYIiXV4tK7/d05c246487c999fb15a8bcdf83e11821/Visa_Infinite_Logo.svg"
				alt="Visa Infinite®"
				width={96}
				height={42}
				class="mx-auto w-full max-w-64"
			/>

			<p>
				Your Visa Infinite card entitles you to automatic Platinum Status when you sign up for Troon Rewards®, for 20%
				saving on golf fees, merchandise and lessons at over 150 golf courses across the United States and world.
			</p>

			<p>
				Your Visa Infinite card also gives access to over 20 high-end, private Troon Privé golf clubs. Play each club
				two times per year for $99 per player per round when you use your Visa Infinite card to book, and bring up to
				three guests at the same rate.
			</p>
		</div>
	);
}
